@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);

  .alert {
    color: if($is-dark-theme, constants.$offwhite, constants.$darkgray);

    &.is-critical {
      border-left: 8px solid constants.$brightred;
      background-color: if($is-dark-theme, constants.$darkgray, rgba(constants.$brightred, 0.05));

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: constants.$brightred;
      }
    }

    &.is-important {
      border-left: 8px solid constants.$orange;
      background-color: if($is-dark-theme, constants.$deepgray, rgba(constants.$orange, 0.05));

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: constants.$orange;
      }
    }

    &.is-helpful {
      border-left: 8px solid constants.$purple;
      background-color: if($is-dark-theme, constants.$darkgray, rgba(constants.$purple, 0.05));

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: constants.$purple;
      }
    }
  }
}

@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);


  details {
    box-shadow: 0 1px 4px 0 rgba(constants.$black, 0.37);
  }

  summary {
    color: if($is-dark-theme, constants.$white, constants.$black);
  }
}
